<template>
  <!--invalid, touched-->
  <div v-if="user && business">

        <div class="row review-admin-contain">
          <div class="col-md-4 col-lg-4 col-xl-3 gate-left">
            <h4 class="text-white">Review Sites</h4>
            <div >
              <label class="d-flex align-items-center"><span class="mr-3">Body Text</span> <MergeFieldsGroup dropdown-size="sm" :show-keyword="true" :show-contact-link="true" :hide-title="true" button-variant="outline-light" :hide-google-links="true" @selected="appendFieldMCE" /></label>
              <vue2-tinymce-editor id="sites-text" ref="sites_text" v-model="business.business.review_gate_settings.sites_body_text" :options="editorOptions" size="sm"></vue2-tinymce-editor>
              <ValidationObserver
                  v-if="business"
                  v-slot="{ handleSubmit, invalid, touched }"
                  ref="updateForm"
              >
                <form
                    class="authentication-form d-flex justify-content-end"
                    @submit.prevent="handleSubmit(updateGate)"
                >
                  <b-button
                      class="btn btn-light btn-sm mt-2"
                      :disabled="loadingUpdate || (invalid && touched)"
                      type="submit"
                  >
                    <b-spinner v-if="loadingUpdate" small />
                    <span v-else>Save</span>
                  </b-button>
                </form>
              </ValidationObserver>
            </div>
            <ValidationObserver
                v-slot="{ handleSubmit }"
                ref="updateForm"
            >
              <form
                  class="authentication-form"
                  @submit.prevent="handleSubmit(addSite)"
              >
                <h4 class="text-white gate-left p-2">Site List <b-button :disabled="addingLink" class="ml-2" size="sm" @click="addNewSite"><i class="uil uil-plus-circle" /> Add</b-button></h4>
                <ul class="list-unstyled site-list">
                  <li v-if="addingLink">
                    <span v-if="siteLink.type !== 'google'" class="d-block">
                      <label class="d-block">Type</label>
                      <select v-model="siteLink.type" class="form-control mb-2" @change="updateSiteLabel">
                        <option v-for="type in availableTypes" :key="type.type" :value="type.type">{{ type.label }}</option>
                      </select>
                    </span>
                    <TextInput v-model="siteLink.url" :disabled="siteLink.type === 'google'" placeholder="https://" label="URL"/>
                    <TextInput v-model="siteLink.label" placeholder="Label" label="Label"/>
                    <label class="d-block">Logo</label>
                    <img v-if="imgSrc(siteLink) !== null" :src="imgSrc(siteLink)" class="mb-2" style="max-width: 50px"/>
                    <input type="file" @change="setLogo">
                    <b-button-group size="md" class="text-center mx-auto w-100">
                      <b-button class="mt-2" size="sm" variant="danger" @click="cancelSite">Cancel</b-button>
                      <b-button class="mt-2" size="sm" type="submit"><b-spinner v-if="loadingUpdate" small />Save</b-button>
                    </b-button-group>
                  </li>
                </ul>
                <table class="table">
                  <draggable v-model="business.business.review_sites" group="sites" tag="tbody" class="site-list" @end="sitesOrderChanged">
                    <tr v-for="(site, i) in business.business.review_sites" :key="i" class="bg-white px-2 mb-2">
                      <td style="vertical-align: middle">
                        <span class="handle-icon"><i class="uil uil-arrow-break"></i></span>
                      </td>
                      <td>
                        <span class="d-block my-2"><a class="btn-outline-dark btn btn-xs" :href="site.url">Review Link</a></span>
                        <span class="d-block">{{site.label}}</span>
                      </td>
                      <td>
                        <span v-if="imgSrc(site) !== null" class="d-block"><img :src="imgSrc(site)" class="mb-2" style="max-width: 50px"/></span>

                      </td>
                      <td>
                        <span class="d-block">
                      <b-button variant="link" class="pl-0" @click="editSite(site)">edit</b-button>
                      <b-button v-if="site.type !== 'google'" variant="link" class="pl-0" @click="deleteSite(site)">delete</b-button>
                    </span>
                      </td>
                    </tr>
                  </draggable>
                </table>
              </form>
            </ValidationObserver>
          </div>
          <div class="col-md-8 col-lg-8 col-xl-9 pr-lg-0">
            <div class="row">
              <div class="col">
                <review-gate v-if="business" :business="business" :show-sites="true" />
              </div>
            </div>
          </div>
        </div>

  </div>

</template>

<script>

import Vue from "vue";
import draggable from 'vuedraggable';
import ReviewGate from "@components/Business/ReviewGate.vue";
import { Vue2TinymceEditor } from "vue2-tinymce-editor";
import MergeFieldsGroup from "@components/Business/Automations/MergeFieldsGroup.vue";
export default {
  components:{
    MergeFieldsGroup,
    ReviewGate,
    draggable,
    Vue2TinymceEditor
  },
  data(){
    return {
        user:null,
        business:null,
        loadingUpdate:false,
        addingLink:false,
        logoUpload:'',
        placeId:null,
        siteLink:{
          url:'',
          logo_path:'',
          type:'custom',
          label:''
        },
        editorOptions:{
          menubar:false,
          plugins: 'autolink charmap code codesample directionality emoticons',
          toolbar1:'fontsizeselect | formatselect | bold italic underline strikethrough forecolor backcolor | alignleft aligncenter alignright | numlist bullist',
          forced_root_block : false,
          content_style: "body { line-height: 1.2; }",
        },
        reviewSites: [
          { type: 'facebook', label: 'Facebook' },
          { type: 'google', label: 'Google' },
          { type: 'yelp', label: 'Yelp' },
          { type: 'custom', label: 'Custom' }        ]
    }
  },
  computed:{
    availableTypes() {
      const typesArray = this.business.business.review_sites.map(site => site.type).filter(site => site !== 'custom');
      return this.reviewSites.filter(type => !typesArray.includes(type.type));
    },
  },
  mounted() {
    this.user = Vue.util.extend({}, this.$store.getters['auth/user']);
    this.getWidget();
  },
  methods:{
    updateSiteLabel(){
      if(this.siteLink.label === '' || !this.siteLink.hasOwnProperty('label')){
        this.siteLink.label = this.reviewSites.filter(site => site.type === this.siteLink.type)[0].label;
      }
    },
    updateGate(){
      this.loadingUpdate = true;
      const business = {
        ...this.business.business, review_gate_settings:this.business.business.review_gate_settings
      }
      this.$store
          .dispatch('business/gateUpdate', business)
          .then(() => {
            this.loadingUpdate = false
          })
          .catch((err) => {
            if (err.response.data.errors) {
              this.$refs.updateForm.setErrors(err.response.data.errors)
            }
            this.loadingUpdate = false
          })
    },
    getWidget() {
      this.$store
          .dispatch('landingPage/getReviewGate', {business_id:this.user.business.unique_id, query_params: 'is_admin=true'})
          .then((res) => {
            this.business = res.reviewGate;
            if(this.business && this.business.business && (!this.business.business.review_sites || !this.business.business.review_sites.length)){
              if (this.user.business.google_maps_url && this.user.business.google_maps_url.includes(this.user.business.place_id) && this.user.business.aux_place_id) {
                this.placeId = this.user.business.aux_place_id;
              } else {
                this.placeId = this.user.business.place_id;
              }
              if(this.placeId === null){
                this.placeId = this.business.business?.location?.place_id;
              }
              if(this.placeId) {
                this.business.business.review_sites = [{
                  url: 'https://search.google.com/local/writereview?placeid=' + this.placeId,
                  logo_path: '',
                  type: 'google',
                  label: 'Google'
                }];
              }
            }
            this.addMissingSitesBodyText(this.business.business);
          })
          .catch((error) => {
            if(error.response.status){
              this.status = error.response.status;
            }
          })
    },
    appendFieldMCE(field){
      const editor = this.$refs.sites_text;
      if (editor) {
        // Insert text at the current cursor position
        editor.editor.selection.setContent(field);
        this.business.business.review_gate_settings.sites_body_text = editor.editor.getContent();
      }
    },
    addNewSite(){
      this.addingLink = true;
      this.siteLink = {
            url:'',
            logo_path:'',
            type:'custom'
      }
    },
    imgSrc(site){
      let src = null;
      if(site.type === 'google' && (!site.hasOwnProperty('logo_path') || site.logo_path === '' || site.logo_path === 'null')){
        src = 'https://rengine.sfo3.cdn.digitaloceanspaces.com/google-icon-100.png';
      }else if(site.type === 'facebook' && (!site.hasOwnProperty('logo_path') || site.logo_path === '' || site.logo_path === 'null')){
        src = 'https://rengine.sfo3.cdn.digitaloceanspaces.com/facebook-100.png';
      }else if(site.type === 'yelp' && (!site.hasOwnProperty('logo_path') || site.logo_path === '' || site.logo_path === 'null')){
        src = 'https://rengine.sfo3.cdn.digitaloceanspaces.com/yelp-logo-100.png';
      }else if(site.logo_path && site.logo_path !== '' && site.logo_path !== 'null'){
        src = this.user.business.cdn_url + site.logo_path;
      }
      return src;
    },
    sitesOrderChanged(){
      if(this.business.business.review_sites.length > 1) {
        this.reOrderSites();
      }
    },
    editSite(site){
      this.siteLink = JSON.parse(JSON.stringify(site));
      this.addingLink = true;
    },
    deleteSite(site){
      this.business.business.review_sites = this.business.business.review_sites.filter(item => item.url !== site.url);
      this.reOrderSites();
    },
    setLogo(event){
      this.logoUpload = event.target.files[0];
    },
    cancelSite(){
      this.addingLink = false;
      this.logoUpload = null;
      if(this.siteLink.type !== 'google') {
        /* this.siteLink.type = 'custom';
        this.siteLink.logo_path = null;
        this.siteLink.url = ''; */
      }
    },
    addMissingSitesBodyText(business){
      if(!business.review_gate_settings.hasOwnProperty('sites_body_text')){
        this.$set(business.review_gate_settings, 'sites_body_text','<p style="text-align: center;"><strong><span style="font-size: 14pt;">Leave Us A Review!</span></strong></p>\n' +
            '<p style="text-align: center;"><span style="font-size: 14pt;">Thanks for stopping by!</span></p>\n' +
            '<p style="text-align: center;"><span style="font-size: 14pt;">We\'d love it if you could leave us a review on one of the sites below!</span></p>');
      }
    },
    reOrderSites(){
      this.$store
          .dispatch('business/reOrderReviewSite', {
            review_sites:JSON.stringify(this.business.business.review_sites) ?? [],
            business_id:this.business.business.id
          })
          .then((resp) => {
            this.business.business = resp.data;
            this.addMissingSitesBodyText(this.business.business);
            this.loadingUpdate = false
          })
          .catch((err) => {
            if (err.response.data.errors) {
              this.$refs.updateForm.setErrors(err.response.data.errors)
            }
            this.loadingUpdate = false
          })
    },
    addSite(){
      this.loadingUpdate = true

      let uploadForm = new FormData();
      if(this.logoUpload !== null && this.logoUpload !== '') {
        uploadForm.append('logo', this.logoUpload);
      }
      uploadForm.append('site_url', this.siteLink.url);
      uploadForm.append('site_logo', this.siteLink.logo_path);
      uploadForm.append('site_type', this.siteLink.type);
      uploadForm.append('site_label', this.siteLink.label);
      uploadForm.append('review_sites', JSON.stringify(this.business.business.review_sites) ?? []);
      uploadForm.append('business_id',this.business.business.id);
      this.$store
          .dispatch('business/addUpdateReviewSite', uploadForm)
          .then((resp) => {
            this.business.business = resp.data;
            this.addMissingSitesBodyText(this.business.business);
            this.loadingUpdate = false
            this.siteLink.type = 'custom';
            this.siteLink.logo_path = null;
            this.siteLink.url = '';
            this.addingLink = false;
          })
          .catch((err) => {
            if (err.response.data.errors) {
              this.$refs.updateForm.setErrors(err.response.data.errors)
            }
            this.loadingUpdate = false
          })
    }
  }
}
</script>
<style lang="scss">
.review-admin-contain{
  margin-top: -10px;
  margin-left:-24.5px;
  min-height: calc(100vh - 72px);
  margin-bottom: 0;
}

.gate-left{
  background: #284373;
  color:#fff;
}
.site-list {
  tr {
    border-bottom: 3px solid #284373;
  }
  li {
    background: #fff;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
    color: #222;

    img {
      max-width: 100px;
      height: auto;
    }
  }
}
.handle-icon{
  cursor: move;
}
.eyebtn{
  margin-top: -5px;
}
</style>
